import { Box } from "@mui/material"

const Center = props => {
  const { children, ...rest } = props

  return (
    <Box display="flex" flex={1} justifyContent="center" {...rest}>
      {children}
    </Box>
  )
}

export default Center
