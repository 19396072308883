import {
  Box,
  Typography,
  Divider,
  Stack,
  Container,
  CircularProgress,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import Center from "../../layouts/Center"
import AccountNotFound from "../../layouts/AccountNotFound"
import Lose from "./components/Lose"
import EmailForm from "./components/EmailForm"
import PhoneForm from "./components/PhoneForm"
import {
  phoneIsPrimary,
  emailIsPrimary,
  getMultilanguageField,
  missingRequiredParmasPhoneCase,
  missingRequiredParmasEmailCase,
} from "../../utils"

const useStyles = makeStyles(theme => ({
  loseSection: {
    background: "#333333",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2.25),
    color: theme.palette.common.white,
  },
  loseContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },
}))

const DeleteAccount = () => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const [searchParams] = useSearchParams()
  const { response, loading, error } = useSelector(state => state.account)

  if (loading) {
    return (
      <Center marginTop={1} marginBottom={1}>
        <CircularProgress size={30} sx={{ color: "#F37C16" }} />
      </Center>
    )
  }

  if (!response || error) {
    return <AccountNotFound />
  }

  if (
    phoneIsPrimary(response.primary) &&
    missingRequiredParmasPhoneCase(
      searchParams.get("platform"),
      searchParams.get("phone"),
      searchParams.get("countryCode"),
    )
  ) {
    //return <AccountNotFound />
  }

  if (
    emailIsPrimary(response.primary) &&
    missingRequiredParmasEmailCase(
      searchParams.get("platform"),
      searchParams.get("email"),
    )
  ) {
    return <AccountNotFound />
  }

  return (
    <>
      <Container className={classes.loseSection}>
        <Typography>{t("text.delete_account_you_will_lose")}</Typography>
        <Box className={classes.loseContainer}>
          <Stack spacing={1.25}>
            <Lose name={t("label.points")} />
            <Lose name={t("label.rewards")} />
            <Lose name={t("label.profiles")} />
          </Stack>
        </Box>
        {response[getMultilanguageField("description")] && (
          <>
            <Divider sx={{ backgroundColor: "white", marginBottom: 1.25 }} />
            <div
              dangerouslySetInnerHTML={{
                __html: `*${response[getMultilanguageField("description")]}`,
              }}
            />
          </>
        )}
      </Container>
      {phoneIsPrimary(response.primary) ? (
        <PhoneForm response={response} />
      ) : (
        <EmailForm response={response} />
      )}
    </>
  )
}

export default DeleteAccount
