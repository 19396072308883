import { useState, useRef, useEffect } from "react"
import {
  Box,
  Typography,
  Stack,
  Container,
  FormHelperText,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useNavigate } from "react-router-dom"
import { useSearchParams } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import useDisclosure from "../../../hooks/useDisclosure"
import { createUserResponse } from "../../../api/apis"
import FDialog from "../../../components/FDialog"
import AlertBox from "./AlertBox"
import ConfirmBox from "./ConfirmBox"
import FCheckBox from "../../../components/FCheckBox"
import FTextField from "../../../components/FTextField"
import FPhoneTextField from "../../../components/FPhoneTextField"
import FButton from "../../../components/FButton"
import { phoneRegExp } from "../../../config/common"
import { getMultilanguageField, othersReasonChecked } from "../../../utils"

const useStyles = makeStyles(theme => ({
  confirmCheckBoxContainer: {
    backgroundColor: "#FF3F3F",
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
  },
  btnNext: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(1.75),
  },
}))

const PhoneForm = props => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { response } = props
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure()
  const otherReasonRef = useRef()
  const otherReasonInputRef = useRef()
  const [data, setData] = useState()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      countryCode: searchParams.get("countryCode") || "852",
      phone: searchParams.get("phone") || "",
      confirm: false,
      reasons: [],
      otherReason: "",
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required(t("message.phone_number_is_required"))
        .matches(phoneRegExp, t("message.phone_number_is_invalid")),
      reasons: Yup.array()
        .min(1, t("message.must_choose_at_least_on_reason"))
        .max(3, t("message.maxium_is_three_reasons")),
      otherReason: Yup.string().when("reasons", {
        is: val => val.includes("reason_6"),
        then: Yup.string().required(t("message.enter_you_reason")),
      }),
    }),
    onSubmit: values => {
      if (!values.confirm) {
        onOpen()
        return
      }
      onOpenConfirm()
      const dataForPost = {
        projectCode: response.code,
        source: searchParams.get("platform"),
        phoneNumber: values.phone,
        countryCode: values.countryCode,
        confirmToProceed: true,
        primary: response.primary,
      }
      values.reasons.forEach((item, index) => {
        dataForPost[`reason${index + 1}`] =
          item === "reason_6"
            ? values.otherReason
            : t(`text.${item}`, { project: response.project })
      })
      setData(dataForPost)
    },
  })

  useEffect(() => {
    if (
      othersReasonChecked(formik.values.reasons) &&
      otherReasonRef.current === window.document.activeElement
    ) {
      otherReasonInputRef && otherReasonInputRef.current.focus()
    }
  }, [formik.values.reasons])

  const saveResponse = async () => {
    try {
      const res = await createUserResponse(data)
      onCloseConfirm()
      res
        ? navigate("/delacc2")
        : alert(
            t("message.request_has_been_submitted_before", {
              primary: formik.values.phone,
              project: response.project,
            }),
          )
    } catch (error) {
      onCloseConfirm()
    }
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Container className={classes.confirmCheckBoxContainer}>
        <FCheckBox
          name="confirm"
          onChange={formik.handleChange}
          label={
            <Typography color="white">
              {t("text.i_confirm_and_proceed")}
            </Typography>
          }
        />
      </Container>
      <Container>
        <Typography paddingTop={2.5} paddingBottom={2}>
          {t("text.in_order_to_delete_please_confirm", {
            project: response.project,
            data: "Phone number",
          })}
        </Typography>
        <FPhoneTextField
          helperText={formik.touched.phone && formik.errors.phone}
          error={formik.touched.phone && !!formik.errors.phone}
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          countryCode="countryCode"
          countryCodeValue={formik.values.countryCode}
          onCountryCodeChange={formik.handleChange}
        />
        <Typography paddingTop={2.5} paddingBottom={2}>
          {t("text.why_you_are_closing_your_account", {
            project: response.project,
          })}
        </Typography>
        <Stack paddingLeft={1.25} paddingRight={1.25} spacing={1}>
          <FCheckBox
            name="reasons"
            value="reason_1"
            onChange={formik.handleChange}
            label={t("text.reason_1", { project: response.project })}
          />
          <FCheckBox
            name="reasons"
            value="reason_2"
            onChange={formik.handleChange}
            label={t("text.reason_2")}
          />
          <FCheckBox
            name="reasons"
            value="reason_3"
            onChange={formik.handleChange}
            label={t("text.reason_3")}
          />
          <FCheckBox
            name="reasons"
            value="reason_4"
            onChange={formik.handleChange}
            label={t("text.reason_4")}
          />
          <FCheckBox
            name="reasons"
            value="reason_5"
            onChange={formik.handleChange}
            label={t("text.reason_5")}
          />
          <FCheckBox
            inputRef={otherReasonRef}
            name="reasons"
            value="reason_6"
            onChange={formik.handleChange}
            label={t("text.reason_6")}
          />
          <Box display="flex">
            <FTextField
              inputRef={otherReasonInputRef}
              disabled={!othersReasonChecked(formik.values.reasons)}
              helperText={formik.errors.otherReason}
              error={!!formik.errors.otherReason}
              onChange={formik.handleChange}
              name="otherReason"
              value={formik.values.otherReason}
              sx={{ marginLeft: 4.5 }}
              fullWidth
            />
          </Box>
          {formik.touched.reasons && formik.errors.reasons && (
            <FormHelperText error>{formik.errors.reasons}</FormHelperText>
          )}
        </Stack>
        <FButton
          variant={formik.values.confirm ? "primary" : "secondary"}
          className={classes.btnNext}
          fullWidth
          title={t("action.next")}
          type="submit"
        />
      </Container>
      <FDialog open={isOpen} onClose={onClose}>
        <AlertBox />
      </FDialog>
      <FDialog open={isOpenConfirm} onClose={onCloseConfirm}>
        <ConfirmBox
          confirmationMessage={
            response[getMultilanguageField("confirmationMessage")] || ""
          }
          onCancel={onCloseConfirm}
          onOk={saveResponse}
        />
      </FDialog>
    </Box>
  )
}

export default PhoneForm
