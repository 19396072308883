import clsx from "clsx"
import { Button } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 24,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    textTransform: "none",
  },
  primary: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: "#333333",
    color: "#FFFFFF",
    "&:hover": {
      background: "#333333",
    },
  },
  secondary: {
    background: "#E0E0E0",
    color: "#828282",
    "&:hover": {
      background: "#E0E0E0",
    },
  },
}))

const FButton = props => {
  const classes = useStyles()
  const { title, className, variant, ...rest } = props

  return (
    <Button
      className={
        variant === "primary"
          ? clsx(classes.root, classes.primary, className)
          : clsx(classes.root, classes.secondary, className)
      }
      {...rest}>
      {title}
    </Button>
  )
}

export default FButton
