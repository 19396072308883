import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"
import VTITLogo from "../assets/images/VTIT_Logo.png"

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F37C16",
    padding: theme.spacing(2),
    minHeight: 56,
  },
  logo: {
    width: 126,
    height: "auto",
  },
}))

const Footer = () => {
  const classes = useStyle()
  const { response } = useSelector(state => state.account)

  return (
    response?.isShowFooterLogo && (
      <Box className={classes.root}>
        <img className={classes.logo} src={VTITLogo} alt="VTIT Logo" />
      </Box>
    )
  )
}

export default Footer
