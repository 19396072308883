import { Checkbox, FormControlLabel } from "@mui/material"
import NoneIcon from "../assets/icons/none.png"
import CheckedIcon from "../assets/icons/checked.png"

const FCheckBox = props => {
  const { label, name, inputRef, ...rest } = props

  return (
    <FormControlLabel
      {...rest}
      sx={{
        marginLeft: 0,
        "& .MuiCheckbox-root": {
          marginTop: "3px",
          alignSelf: "flex-start",
        },
      }}
      control={
        <Checkbox
          inputRef={inputRef}
          sx={{ padding: 0, marginRight: 1.75 }}
          name={name}
          icon={<img width={20} height={20} src={NoneIcon} alt="none" />}
          checkedIcon={
            <img width={20} height={20} src={CheckedIcon} alt="checked" />
          }
        />
      }
      label={label}
    />
  )
}

export default FCheckBox
