import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const AlertBox = () => {
  const [t] = useTranslation("common")

  return (
    <Box style={{ background: "#FFFFFF", padding: 40 }}>
      <Typography textAlign="center">
        {t("message.please_ticked_box_for_confirm_request")}
      </Typography>
    </Box>
  )
}

export default AlertBox
