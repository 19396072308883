import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  lng: "eng",
}

export const i18nSlice = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    changeLng: (state, action) => {
      state.lng = action.payload
    },
  },
})

export const { changeLng } = i18nSlice.actions

export default i18nSlice.reducer
