import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getProjectInfo } from "../../../api/apis"

export const fetchAccountInfo = createAsyncThunk(
  "member/fetchAccountInfo",
  async projectCode => {
    return await getProjectInfo(projectCode)
  },
)

const initialState = {
  response: null,
  loading: true,
  error: null,
}

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAccountInfo.pending]: state => {
      state.loading = true
    },
    [fetchAccountInfo.fulfilled]: (state, action) => {
      state.loading = false
      state.response = action.payload
    },
    [fetchAccountInfo.rejected]: state => {
      state.loading = false
      state.error = true
    },
  },
})

export default accountSlice.reducer
