import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Circle from "../../../assets/icons/circle.png"

const useStyles = makeStyles(theme => ({
  circleIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
  },
}))

const Benefit = props => {
  const classes = useStyles()
  const { name } = props

  return (
    <Box display="flex" alignItems="center">
      <img className={classes.circleIcon} src={Circle} alt="Circle" />
      <Typography>{name}</Typography>
    </Box>
  )
}

export default Benefit
