import PropTypes from "prop-types"
import { Box, Typography, Divider, ButtonBase } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useTranslation } from "react-i18next"
import DeleteIcon from "../../../assets/icons/delete.png"

const useStyles = makeStyles(theme => ({
  root: {
    background: "transparent",
    position: "relative",
  },
  container: {
    background: "linear-gradient(180deg, #FF3F3F 0%, #FE9F9F 19.77%)",
    borderRadius: 20,
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: 80,
    height: 80,
  },
  topDivider: {
    background: "transparent",
    height: 40,
    border: "none",
  },
  topContentDivider: {
    background: "transparent",
    height: 13,
    border: "none",
  },
  content: {
    background: theme.palette.common.white,
    boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    padding: "40px 40px 27px 40px",
  },
  txtConfirmDeletion: {
    textTransform: "uppercase",
    fontWeight: 600,
    textAlign: "center",
  },
  txtDes: {
    textAlign: "center",
    color: "#333333",
    fontWeight: 300,
  },
  btnContainer: {
    background: theme.palette.common.white,
    boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.3)",
    display: "flex",
  },
  btnCancel: {
    flex: 1,
    background: "#E0E0E0",
    color: "#828282",
    fontWeight: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  btnDeleteIt: {
    flex: 1,
    background: "#FF3F3F",
    color: theme.palette.common.white,
    fontWeight: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

const ConfirmBox = props => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { onCancel, onOk, confirmationMessage } = props

  return (
    <Box className={classes.root}>
      <img className={classes.deleteIcon} src={DeleteIcon} alt="delete icon" />
      <Divider className={classes.topDivider} />
      <Box className={classes.container}>
        <Divider className={classes.topContentDivider} />
        <Box className={classes.content}>
          <Typography className={classes.txtConfirmDeletion}>
            {t("text.confirm_deletion")}
          </Typography>
          <div
            className={classes.txtDes}
            dangerouslySetInnerHTML={{ __html: confirmationMessage }}
          />
        </Box>
        <Box className={classes.btnContainer}>
          <ButtonBase onClick={onCancel} className={classes.btnCancel}>
            {t("action.cancel")}
          </ButtonBase>
          <ButtonBase onClick={onOk} className={classes.btnDeleteIt}>
            {t("action.delete_it")}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  )
}

PropTypes.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  confirmationMessage: PropTypes.string,
}

export default ConfirmBox
