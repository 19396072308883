import { useState, useEffect } from "react"

const useDisclosure = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState)

  useEffect(() => {
    if (isOpen !== initialState) {
      setIsOpen(initialState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState])

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const toggle = () => (isOpen ? onClose() : onOpen())

  return { isOpen, onOpen, onClose, toggle }
}

export default useDisclosure
