import { store } from "../store"
import { platforms, countryCodes } from "../config/common"

export const phoneIsPrimary = field => {
  return field && field === "phone"
}

export const emailIsPrimary = field => {
  return field && field === "email"
}

export const getMultilanguageField = fieldName => {
  const { i18n } = store.getState()

  return `${fieldName}${capitalizeFirstLetter(i18n.lng)}`
}

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const includePlatform = platform => {
  return platform && platforms.includes(platform)
}

const includePhone = phone => {
  return phone && Number(phone)
}

const includeEmail = email => {
  return email
}

const includeCountryCode = countryCode => {
  return countryCode && countryCodes.includes(countryCode)
}

export const missingRequiredParmasPhoneCase = (
  platform,
  phone,
  countryCode,
) => {
  return (
    !includePlatform(platform) ||
    !includePhone(phone) ||
    !includeCountryCode(countryCode)
  )
}

export const missingRequiredParmasEmailCase = (platform, email) => {
  return !includePlatform(platform) || !includeEmail(email)
}

export const othersReasonChecked = reasons => {
  return Array.isArray(reasons) && reasons.includes("reason_6")
}
