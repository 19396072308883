import { TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    "& .Mui-focused ": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#4F4F4F",
        borderWidth: 1,
      },
    },
  },
  input: {
    "& .MuiInputBase-input": {
      paddingTop: "7px",
      paddingBottom: "7px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E0E0E0",
      borderRadius: "2px",
    },
  },
}))

const FTextField = props => {
  const classes = useStyles()
  const { startAdornment, ...rest } = props

  return (
    <TextField
      className={classes.root}
      {...rest}
      InputProps={{
        startAdornment,
        className: classes.input,
      }}
    />
  )
}

export default FTextField
