import { forwardRef } from "react"
import PropTypes from "prop-types"
import { Dialog, DialogContent, Slide, alpha } from "@mui/material"
import { makeStyles } from "@mui/styles"

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      display: "block",
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },
  dialogContent: {
    padding: 0,
    borderRadius: "24px",
  },
}))

const FDialog = props => {
  const classes = useStyles()
  const { open, onClose, children } = props

  return (
    <Dialog
      className={classes.dialog}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: alpha("#000000", 0.5),
          backdropFilter: "blur(1px)",
        },
      }}
      TransitionComponent={Transition}
      open={open}>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

FDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

export default FDialog
