import AxiosClient from "../config/axiosClient"

export const createUserResponse = async data => {
  try {
    const res = await AxiosClient.post(`/response`, data)
    return res.data
  } catch (err) {
    throw err
  }
}

export const getProjectInfo = async projectCode => {
  try {
    const res = await AxiosClient.get(`/project/${projectCode}`)
    return res.data
  } catch (err) {
    throw err
  }
}

export const getProjectInfos = async projectCode => {
  try {
    const res = await AxiosClient.get(`/project/${projectCode}`)
    return res.data
  } catch (err) {
    throw err
  }
}
