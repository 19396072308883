import { Box, Container } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"
import NoImage from "../assets/images/No_Image.jpeg"

const useStyle = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  logo: {
    maxWidth: 100,
    maxHeight: 40,
    display: "block",
  },
}))

const Header = () => {
  const classes = useStyle()
  const { response } = useSelector(state => state.account)

  return (
    <Box>
      <Container className={classes.container}>
        <img
          className={classes.logo}
          src={response?.logoImage ? response.logoImage : NoImage}
          alt="Logo"
        />
      </Container>
    </Box>
  )
}

export default Header
