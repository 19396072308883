import { configureStore } from "@reduxjs/toolkit"
import accountReducer from "./features/account/accountSlice"
import i18nReducer from "./features/i18n/i18nSlice"

export const store = configureStore({
  reducer: {
    account: accountReducer,
    i18n: i18nReducer,
  },
})
