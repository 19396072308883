import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import Center from "./Center"

const AccountNotFound = () => {
  const [t] = useTranslation("common")

  return (
    <Center marginTop={1} marginBottom={1}>
      <Typography>{t("message.account_not_found")}</Typography>
    </Center>
  )
}

export default AccountNotFound
