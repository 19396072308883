import PropTypes from "prop-types"
import { InputAdornment, Box, Select, MenuItem, Divider } from "@mui/material"
import { makeStyles } from "@mui/styles"
import FTextField from "./FTextField"
import { countryCodes } from "../config/common"
import PhoneIcon from "../assets/icons/phone.png"
import ArrowDownIcon from "../assets/icons/arrow_down.png"

const useStyles = makeStyles(theme => ({
  select: {
    alignItems: "center",
    "& .MuiSelect-select": {
      paddingRight: `${theme.spacing(0.75)} !important`,
      paddingLeft: `${theme.spacing(1)} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      maxHeight: "100%",
    },
  },
  menuItem: {
    minHeight: 32,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  divider: {
    backgroundColor: "#E0E0E0",
    height: 18,
    width: 1,
    alignSelf: "center",
    marginLeft: theme.spacing(1.5),
  },
}))

const IconComponent = () => {
  return <img width={8} src={ArrowDownIcon} alt="dropdown" />
}

const FPhoneTextField = props => {
  const classes = useStyles()
  const {
    value,
    countryCodeValue,
    onChange,
    onCountryCodeChange,
    name,
    countryCode,
    helperText,
    error,
  } = props

  return (
    <FTextField
      helperText={helperText}
      error={error}
      name={name}
      onChange={onChange}
      value={value}
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <Box display="flex" alignItems="center">
            <img width={15} src={PhoneIcon} alt="phone" />
            <Select
              name={countryCode}
              onChange={onCountryCodeChange}
              className={classes.select}
              value={countryCodeValue}
              IconComponent={IconComponent}>
              {countryCodes.map((countryCodes, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  value={countryCodes}>
                  {`+${countryCodes}`}
                </MenuItem>
              ))}
            </Select>
            <Divider
              className={classes.divider}
              orientation="vertical"
              flexItem
            />
          </Box>
        </InputAdornment>
      }
    />
  )
}

FPhoneTextField.propTypes = {
  value: PropTypes.string.isRequired,
  countryCodeValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCountryCodeChange: PropTypes.func.isRequired,
}

export default FPhoneTextField
