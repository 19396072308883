import React from "react"
import ReactDOM from "react-dom/client"
import i18next from "i18next"
import { I18nextProvider } from "react-i18next"
import { BrowserRouter } from "react-router-dom"
import { store } from "./store/index"
import { Provider } from "react-redux"
import { CssBaseline } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { defaultTheme } from "./themes"
import common_eng from "./locales/eng/eng.json"
import common_zho from "./locales/zho/zho.json"
import common_chi from "./locales/chi/chi.json"
import "./index.css"
import App from "./App"

i18next.init({
  interpolation: { escapeValue: true },
  lng: "eng", // language to use
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "span"],
  },
  resources: {
    eng: {
      common: common_eng, // 'common' is our custom namespace
    },
    zho: {
      common: common_zho,
    },
    chi: {
      common: common_chi,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </StyledEngineProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
)
