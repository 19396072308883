import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"
import { getMultilanguageField } from "../../utils"
import Illustration from "../../assets/images/Illustration.png"

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: "center",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  illustration: {
    width: 266,
    marginTop: 72,
    marginBottom: 72,
  },
  txt: {
    fontWeight: 300,
    maxWidth: 260,
    margin: "0 auto",
    marginBottom: theme.spacing(3),
  },
}))

const DeleteAccount2 = () => {
  const classes = useStyles()
  const { response } = useSelector(state => state.account)

  return (
    <Box className={classes.root}>
      <img
        className={classes.illustration}
        src={Illustration}
        alt="Illustration"
      />
      {response && response[getMultilanguageField("thankyouMessage")] && (
        <div
          className={classes.txt}
          dangerouslySetInnerHTML={{
            __html: response[getMultilanguageField("thankyouMessage")],
          }}
        />
      )}
    </Box>
  )
}

export default DeleteAccount2
