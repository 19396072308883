import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 30,
          paddingRight: 30,
        },
      },
    },
  },
})

export default theme
