import { useEffect } from "react"
import i18next from "i18next"
import { useDispatch } from "react-redux"
import { Routes, Route, Navigate } from "react-router-dom"
import { useSearchParams } from "react-router-dom"
import { Box } from "@mui/material"
import { fetchAccountInfo } from "./store/features/account/accountSlice"
import { changeLng } from "./store/features/i18n/i18nSlice"
import Header from "./layouts/Header"
import Footer from "./layouts/Footer"
import DeleteAccount from "./containers/deleteAcount/DeleteAccount"
import DeleteAccount2 from "./containers/deleteAcount/DeleteAccount2"

function App() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get("lang")) {
      const lang = searchParams.get("lang")
      i18next.changeLanguage(lang)
      dispatch(changeLng(lang))
    }
    dispatch(fetchAccountInfo(searchParams.get("project-code")))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      style={{ minHeight: "100vh" }}
      className="App"
      display="flex"
      flexDirection="column">
      <Header />
      <Routes>
        <Route path="/" element={<Navigate replace to="/delacc" />} />
        <Route path="delacc" element={<DeleteAccount />} />
        <Route path="delacc2" element={<DeleteAccount2 />} />
      </Routes>
      <Footer />
    </Box>
  )
}

export default App
