import axios from "axios"

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
}

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: false,
  headers,
})

axiosClient.interceptors.request.use(
  config => {
    return config
  },
  err => {
    return Promise.reject(err)
  },
)
axiosClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.response)
  },
)

export default axiosClient
